define('ember-components/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-components/constants'], function (exports, _applicationRouteMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      hash = Ember.RSVP.hash,
      service = Ember.inject.service;
  exports.default = Route.extend(_applicationRouteMixin.default, {
    currentUser: service(),
    session: service(),
    fastboot: service(),

    title: function title(tokens) {
      if (tokens.length) {
        return tokens;
        //return `${tokens.join(' - ')} - Warehouse Exchange`;
      }
      return 'Warehouse Exchange';
    },
    init: function init() {
      this._super();
      if (this.get('fastboot.isFastBoot')) {
        return;
      }

      var urlParams = new URLSearchParams(window.location.search);
      var token = urlParams.get('token');

      if (token) {
        localStorage.setItem('ember_simple_auth-session', '{"authenticated":{"authenticator":"authenticator:token","key":"' + token + '"}}');
        localStorage.setItem('isImpersonate', true);
        this._loadCurrentUser();
      }

      $(window).scroll(function () {
        var nav = $('#main-navbar');
        var top = 60;
        if ($(window).scrollTop() >= top) {
          nav.addClass('fixed');
        } else {
          nav.removeClass('fixed');
        }
      });
    },
    beforeModel: function beforeModel() {
      var _this = this;

      return this._loadCurrentUser().then(function (data) {
        var user = data.get('user');

        if (user && !user.get('profileIsFull')) {
          window.localStorage.setItem(_constants.default.IS_CREATE_ACCOUNT, 'isCreate');
          _this.replaceWith('user-profile');
        }
      });
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;

      return this.get('currentUser').load().catch(function () {
        return _this2.get('session').invalidate();
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    model: function model() {
      return hash({
        user: this.store.createRecord('user'),
        emailRequest: this.store.createRecord('email-request'),
        facilities: this.get('session').get('isAuthenticated') ? this.store.findAll('facility', { reload: true }) : []
      });
    },


    actions: {
      error: function error(err) {
        if (err.errors) {
          switch (err.errors[0].status) {
            case '404':
              this.transitionTo('errors/404', ['']);
              break;
            case '401':
              this.intermediateTransitionTo('errors/401');
              break;
            case '403':
              if (err.errors[0].detail === 'Invalid token.') {
                this.get('session').invalidate();
              }
              this.transitionTo('errors/403');
              break;
            default:
              this.transitionTo('errors/404', ['']);
          }
        }

        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  });
});