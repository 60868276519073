define('ember-components/router', ['exports', 'ember-components/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service()

    // didTransition() {
    //   this._super(...arguments);
    //   this._trackPage();
    // },

    /*
     * This code is used for Google Analytics injection
     * It works using 'ember-metrics'
     * https://github.com/poteto/ember-metrics
     */
    // _trackPage() {
    //   Ember.run.scheduleOnce('afterRender', this, () => {
    //     const page = this.get('url');
    //     const title = this.getWithDefault('currentRouteName', 'unknown');
    //
    //     Ember.get(this, 'metrics').trackPage({ page, title });
    //   });
    // },
  });

  Router.map(function () {
    this.route('home', { path: '/' }, function () {
      this.route('email-confirm', { path: '/email-confirm/:key' });
      this.route('password-reset-confirm', { path: '/password-reset/confirm/:uid/:key' });
    });

    this.route('supplier', function () {
      this.route('onboarding');
      this.route('company', function () {
        this.route('register');
      });
      this.route('space', function () {
        this.route('list');
      });
    });

    // Create facility App Routes
    this.route('facility-create', { path: 'supplier/facility-setup/:facilityID' }, function () {
      this.route('step1');
      this.route('step2');
      this.route('step3');
      this.route('step4');
    });
    this.route('dashboard-buyer', { path: 'buyer/dashboard' });
    this.route('engagements-table', { path: ':user_type/dashboard/engagements' });
    this.route('dashboard-supplier', { path: 'supplier/dashboard' });

    this.route('preferences-buyer', { path: 'buyer/preferences' });
    this.route('preferences-supplier', { path: 'supplier/preferences' });
    this.route('user-notifications', { path: 'user/notifications' });
    this.route('user-profile', { path: 'user/profile' });
    this.route('engagements', function () {
      this.route('invoice-create', { path: 'invoice' });
      this.route('invoice-edit', { path: ':engagement_id/invoice/:invoice_id' });
      this.route('invoice-details', { path: ':engagement_id/invoice-details/:invoice_id/' });
      this.route('invoice-pay', { path: ':engagement_id/invoice-pay/:invoice_id/' });
      this.route('buyer', { path: '/:engagement_id/buyer' });
      this.route('supplier', { path: '/:engagement_id/supplier' });
      this.route('revise-rates', { path: '/:engagement_id/revise-rates' });
      this.route('request', { path: '/request/:facility_id' });
      this.route('request-amendment', { path: '/request-amendment/:facility_id' });
      this.route('payment-confirmation', { path: '/payment-confirmation/:facility_id' });
    });

    this.route('supplier', function () {
      this.route('onboarding');
      this.route('company-verify', { path: ':user_id/company/:company_id/:action_type/' });
    });

    // listing details app routes
    // TODO (WAR-430): Remove old facility-listing-details and rename new one to facility-details.
    this.route('new-facility-listing-details', { path: 'warehouse/:facilityID' });
    this.route('new-facility-listing-details-seo', { path: 'search/warehouses/:address/for-rent/:facilityID' });
    this.route('facility-listing-details', { path: 'warehouse-old/:facilityID' });
    this.route('facility-order', { path: 'warehouse/:facilityID/order' });
    this.route('old-facility-order', { path: 'facilities/:facilityID/order' });
    this.route('reserve-space');

    // facility search
    this.route('old-search', { path: 'supplier/facility/search' });
    this.route('seo-search', { path: 'search/warehouses/:stateCode/:cityCode/for-rent' });

    // resource routs
    this.route('resources', function () {
      this.route('faq');
      this.route('about-us', { path: 'about' });
      this.route('terms-of-services');
      this.route('privacy-policy');
    });

    this.route('messages', function () {
      this.route('chat', { path: 'messages/:conversationId' });
    });

    this.route('errors/401');
    this.route('errors/403');
    this.route('errors/404', { path: '/*path' });

    // static pages
    this.route('pages', function () {
      this.route('engagement-details-current');
      this.route('invoice');
      this.route('invoice-edit');
      this.route('complete-form');
      this.route('contact-us');
      this.route('messages');
      this.route('supplier-profile');
      this.route('revise-rates');
    });

    this.route('feedback', { path: 'contacts' });
    this.route('request-tour', { path: 'request-tour' });
    this.route('ask-form', { path: 'ask-form' });
  });

  exports.default = Router;
});